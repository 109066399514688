(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

$(document).ready(function () {
  var $hamburger = $('.hamburger'),
    $nav = $('.m-nav'),
    $wrapper = $('.wrapper');
  $hamburger.click(function (e) {
    $hamburger.toggleClass('is-active');
    $nav.toggleClass('show');
    $wrapper.toggleClass('fixed');
    $('.search-overlay').removeClass('show');
    $('.subscribe-overlay').removeClass('show');
  });
  $('.nav li a').click(function () {
    $wrapper.removeClass('fixed');
  });
  $('.search-btn').click(function (e) {
    $wrapper.removeClass('fixed');
    $hamburger.removeClass('is-active');
    $nav.removeClass('show');
    $('.subscribe-overlay').removeClass('show');
    document.getElementById("mc-embedded-subscribe-form-top").reset();
    $('.search-overlay').addClass('show');
    document.getElementById("search-form").reset();
    $('#search').focus();
  });
  $('.subscribe-btn').click(function (e) {
    $wrapper.removeClass('fixed');
    $hamburger.removeClass('is-active');
    $nav.removeClass('show');
    $('.search-overlay').removeClass('show');
    document.getElementById("search-form").reset();
    $('.subscribe-overlay').addClass('show');
    document.getElementById("mc-embedded-subscribe-form-top").reset();
    $('#mce-EMAIL').focus();
  });
  $('.close-btn').click(function (e) {
    $('.search-overlay').removeClass('show');
    document.getElementById("search-form").reset();
    $('.m-nav').removeClass('show');
    $wrapper.removeClass('fixed');
    $hamburger.removeClass('is-active');
  });
  $('.close-btn.subscribe').click(function (e) {
    $('.subscribe-overlay').removeClass('show');
    document.getElementById("mc-embedded-subscribe-form-top").reset();
    $('.m-nav').removeClass('show');
    $wrapper.removeClass('fixed');
    $hamburger.removeClass('is-active');
  });
  $('.image').each(function () {
    if ($(this).attr('title').length > 0) {
      var thisclass = $(this).attr('class');
      var thistext = $(this).attr('alt');
      var thisfloat = this.style["float"];
      var thiswidth = this.style.width;
      $(this).removeClass(thisclass);
      $(this).wrap('<div class="' + thisclass + '" style=" float: ' + thisfloat + '; width: ' + thiswidth + '"></div>');
      $(this).parent().append('<p>' + thistext + '</p>');
    }
  });
  var mixer = mixitup('.job-listing-summary');
});
$(window).load(function () {
  // Animate loader off screen
  $('.loader').addClass('off');
  $('.spinner').addClass('off');
});
var acc = document.getElementsByClassName("reveal");
var i;
for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function () {
    this.classList.toggle("active");
    var panelContainer = this.nextElementSibling;
    if (panelContainer.style.maxHeight) {
      panelContainer.style.maxHeight = null;
    } else {
      panelContainer.style.maxHeight = panelContainer.scrollHeight + "px";
    }
  });
}

},{}]},{},[1]);
